merge = require('lodash.merge');
import { companySerialNoChecker } from '../../utils/invoice';
import { validateBirthday, getBirthdayFieldValue, getJsMonth } from '../../utils/birthday';
import { BIRTHDAY_ERROR } from '../constants/user';

app.service('checkoutService', [
  '$http'
  'cart'
  '$filter'
  'featureService'
  'customValidator'
  '$rootScope'
  'mainConfig'
  '$uibModal'
  'customValidationService'
  'intlTelInputService'
  (
    $http
    cart
    $filter
    featureService
    customValidator
    $rootScope
    mainConfig
    $uibModal
    customValidationService
    intlTelInputService
  ) ->
    headers = { "X-Requested-With": "XMLHttpRequest", "cacheKey": mainConfig.currentPath }
    @pageId = null
    requestParamBlacklist = ['affiliate_ref_id', 'affiliate_source', 'sl-ref']
    {
      customerIntlKey: '#order-customer-phone.intl-tel-input-checkout';
      recipientIntlKey: '#recipient-phone.intl-tel-input-checkout';

      getRequestParams: () ->
        _.tap { page_id: @pageId }, (hash) ->
          _.each(
            (window.location.search).replace(/.*\?/, '').split('&'),
            (chunk) ->
              parts = chunk.split('=')
              hash[parts[0]] = parts[1] if parts.length == 2 && !requestParamBlacklist.includes(parts[0])
          )

      getBrowserParams: () ->
        browserParams = {}

        if $rootScope.isDeviceSupportSLPApplePay == false
          browserParams['support_slp_apple_pay'] = false

        if $rootScope.isDeviceSupportSLPGooglePay == false
          browserParams['support_slp_google_pay'] = false

        browserParams

      getPartialParams: (section, key) ->
        if section == 'cart' && ['form', 'summary'].includes(key)
          return merge(@getRequestParams(), @getBrowserParams())

        @getRequestParams()

      requestPartial: (section, key, data, options = {}) ->
        payload = {
          method: (if data? then "PUT" else "GET")
          url: sprintf("%s/%s", cart.paths[section], key)
          params: @getPartialParams(section, key)
          data: data
          headers: headers
        }
        $http(merge(payload, options))

      requestValidate: () ->
        $http({
          method: "GET",
          url: sprintf("%s/validate", cart.paths['cart']),
          headers: headers,
          params: merge(@getBrowserParams(), @getRequestParams()),
        })

      getFormFields: ($form, scope) ->
        _.chain($form)
        .map((form) ->
          $form = angular.element(form)
          formName = $form.attr("name")
          if formName && (scope is undefined || _.has(scope, formName)) then $form.find("input,select,textarea,span").toArray() else []
        )
        .flatten()
        .map((field) -> angular.element(field))
        .reject(($field) ->
          fieldName = $field.attr("name")
          fieldTypeIsHidden = $field.attr('type') == 'hidden'

          _.isEmpty(fieldName) || ($field.is(":hidden") && !fieldTypeIsHidden)
        )
      getFormFieldValue: ($field) ->
        return {} if not $field.attr("name")
        if $field.attr("type") is "checkbox"
          $field.is(":checked")
        else if $field.attr('type') is 'radio'
          $('input[name="' + $field.attr('name') + '"]:checked').val();
        else if $field.hasClass("input-datetime")
          if _.isEmpty($field.val()) then null else dayjs($field.val(), "YYYY/MM/DD").toDate()
        else if $field.hasClass("input-date")
          if _.isEmpty($field.val()) then null else dayjs.utc($field.val(), 'YYYY/MM/DD').add(dayjs($field.val()).utcOffset(), 'minute').toDate()
        else if $field.attr("data-json")?
          try
            JSON.parse($field.val())
          catch e
            {}
        else if $field.is("span")
          $field.text()
        else if $field.attr("translations-label")?
          # Shopline API handling, try remove it if possible
          _.tap({ value: $field.val() }, (object) ->
            labelKey = $field.attr("translations-label")
            object[labelKey] = JSON.parse($field.attr(labelKey))
            object['field_id'] = $field.attr('field_id') if $field.attr('field_id')?
          )
        else if $field.attr("value-prefix")?
          $field.attr("value-prefix") + $field.val()
        else
          $field.val()

      validateFormField: ($field, isOnlyTwStore) ->
        fieldName =
          if $field.attr('translations-label')?
            $filter('translateModel')(JSON.parse($field.attr($field.attr('translations-label'))))
          else if $field.attr("name") == "delivery_address[region]" || $field.attr("name") == "delivery_address[district]"
            translationsKey = if $field.attr('name') == 'delivery_address[region]' then 'region' else 'district'
            $filter('translate')("delivery_address.#{translationsKey}.#{$field.data('country')}")
          else
            name = $field.attr('name').match(/([a-zA-Z0-9\_\-]+)/g).join(".").toUnderscore()
            if featureService.hasFeature('mobile_signup_p2') && name == 'order.customer_email'
              # TODO: Unify the naming of email translation when remove rollout feature "mobile_signup_p2"
              # To display different email translation when enalbed rollout feature "mobile_singup_p2"
              $filter('translate')('order.email')
            else if $field.attr('custom-translation')
              $filter('translate')($field.attr('custom-translation'))
            else
              $filter('translate')(name)

        error =
          if $field.attr("name") == "policy" && !$field.is(":checked") && $field.attr("required")?
            $filter('translate')('form.validation.policy')
          else if $field.attr("required")? && (
               ($field.val() == "") ||
               ($field.attr("type") == "checkbox" && !$field.is(":checked")) ||
               ($field.is("select") && (!$field.val() || $field.val() == "?"))
             )
            $filter('translate')('form.validation.required', { field_name: fieldName})
          else if $field.attr("type") == "email" && ($field.attr("required")? || $field.val()) && !customValidator.EMAIL_REGEX.test($field.val())
            $filter('translate')('form.validation.email',{field_name: fieldName})
          else if $field.attr("id") == "id_no" && $field.val().length > 0 && !customValidationService.checkTwId($field.val())
            $filter('translate')('form.validation.id_no')
          else if $field.val() && $field.attr("ng-pattern")? && !(new RegExp($field.attr("ng-pattern").replace(/^\/(.*)\/$/, "$1")).test($field.val()))
            if ($field.attr('id') == 'recipient-phone') && !_.isEmpty($field.data('country'))
              $filter('translate')("form.validation.pattern.#{$field.attr('id')}.#{$field.data('country')}", { field_name: fieldName } )
            else if ($field.attr('id') == 'recipient-name')
              if ($field.attr('ng-pattern') == customValidationService.englishAddressPattern)
                $filter('translate')('form.validation.pattern.711_cross_border.delivery_address')
              else
                limit_length = if (/.*[\u4e00-\u9fa5]+.*$/.test($field.val())) then 5 else 10
                $filter('translate')("form.validation.pattern.#{$field.attr('id')}", {limit_length: limit_length})
            else if $field.attr('field-length')? && Number.isInteger(Number($field.attr('field-length')))
              $filter('translate')('form.validation.pattern.recipient-phone.fix_size', { max_count: $field.attr('field-length') })
            else if $field.attr('min-length')? && Number.isInteger(Number($field.attr('min-length')))
              $filter('translate')('form.validation.pattern.recipient-phone.above_size', { max_count: $field.attr('min-length') })
            else if $field.attr('beginning-stint')?
              # will replace when wording are support all languages
              $filter('translate')('form.validation.pattern.recipient-phone.beginning_stint',{ stint_charts: $field.attr('beginning-stint') })
            else if $field.attr('ng-pattern') == customValidationService.englishAddressPattern
              $filter('translate')('form.validation.pattern.711_cross_border.delivery_address')
            else
              $filter('translate')('form.validation.pattern',{field_name: fieldName})
          else if $field.is('select') && $field.attr('name') == 'order[invoice][mailing_address]' && $field.val().length > 100
            $filter('translate')('form.validation.maxlength.with.number', {
              field_name: $filter('translate')('address.fields.address'),
              max_length: 100
            })
          else if $field.attr('name') == 'max-mailing-address-length'
            $filter('translate')('form.validation.maxlength.with.number', {
              field_name: $filter('translate')('address.fields.address'),
              max_length: 100
            })
          else if $field.attr("ng-maxlength")? && $field.val().length > parseInt($field.attr("ng-maxlength"), 10)
            if $field.attr('name') == 'order[invoice][mailing_address]'
              $filter('translate')('form.validation.maxlength.with.number', {
                field_name: $filter('translate')('address.fields.address'),
                max_length: 100
              })
            else
              $filter('translate')('form.validation.maxlength',{field_name: fieldName})
          else if $field.attr("ng-minlength")? && $field.val().length < parseInt($field.attr("ng-minlength"), 10)
            $filter('translate')('form.validation.minlength',{field_name: fieldName})
          else if $field.attr("invalid-date")?
            $filter('translate')('form.validation.pattern',{field_name: fieldName})
          else if $field.attr('name') == 'order[invoice][tax_id]' && !companySerialNoChecker($field.val())
            $filter('translate')('form.validation.pattern',{field_name: fieldName})
          else if $field.attr('name') == 'birthday'
            year = getBirthdayFieldValue($field.find('.year-select'))
            month = getBirthdayFieldValue($field.find('.month-select'))
            date = getBirthdayFieldValue($field.find('.date-select'))
            errorCode = validateBirthday({
              birthdayFormat: $rootScope.birthdayFormat,
              year: year,
              month: getJsMonth(month),
              date: date,
              minAge: $rootScope.minimumAgeLimit,
              nullable: !$rootScope.birthdayRequired
            })
            $rootScope.hasCheckoutBirthdaySubmitError = errorCode != null
            $rootScope.$apply()
            if errorCode == BIRTHDAY_ERROR.REQUIRED
              $filter('translate')('form.validation.birthday.required')
            else if errorCode == BIRTHDAY_ERROR.INVALID
              $filter('translate')('form.validation.birthday.invalid')
            else if errorCode == BIRTHDAY_ERROR.MIN_AGE
              $filter('translate')('form.validation.birthday.invalid.minimum_age_limit', { minimumAgeLimit: $rootScope.minimumAgeLimit })
            else
              null
          else if $field.attr('ng-model') == 'addr[preference.field_name]' && $field.attr('type') == 'text'
            countriesAllowNonEnglishAddress = ['HK', 'MO', 'SG', 'MY']
            if !countriesAllowNonEnglishAddress.includes(cart.country) && ['cross_border_711_store_pick_up', 'cross_border_711_home_delivery'].includes(cart.delivery_option.region_type)
              if !(new RegExp(customValidationService.englishAddressPattern.replace(/^\/(.*)\/$/, "$1")).test($field.val()))
                $filter('translate')('form.validation.pattern.711_cross_border.delivery_address')
          else
            null

        customerPhoneValidationObj = { wording: { field_name: fieldName } };
        recipientPhoneValidationObj = { 
          wording: { field_name: fieldName },
          validateTarget: { mobilePhoneValidation: isOnlyTwStore },
          invalidKey: { mobilePhoneValidation: 'form.validation.mobile.error' }
        };
        if $field.attr('id') == 'order-customer-phone' && $(this.customerIntlKey).length > 0
          errArray = intlTelInputService.validatePhone($(this.customerIntlKey), customerPhoneValidationObj);
          if errArray && errArray.length
            error = errArray[0].message;
        if $field.attr('id') == 'recipient-phone' && $(this.recipientIntlKey).length > 0
          errArray = intlTelInputService.validatePhone($(this.recipientIntlKey), recipientPhoneValidationObj);
          if errArray && errArray.length
            error = errArray[0].message;

        $field.data('error-message', error) if error?
        error?

      getElement: (name, element) ->
        return if element? then element.find(sprintf("*[name='%s']:visible", name)).last() else angular.element(sprintf("*[name='%s']", name))

      getElementValue: (name, element) ->
        this.getFormFieldValue(this.getElement(name, element))

      openTrialOrderLimitPopup: (isAllowCheckout) ->
        return $uibModal.open({
          templateUrl: require('../../../../../public/themes/shared/cart/templates.dialog.trial_order.html'),
          controller: [
            '$scope'
            '$uibModalInstance'
            'ordersService'
            '$translate'
            'mainConfig'
            'modalTypes'
            (
              $scope
              $uibModalInstance
              ordersService
              $translate
              mainConfig
              modalTypes
            ) ->
              $rootScope.$emit('modal.open', { modalType: modalTypes.TRAIL_ORDER_LIMIT });
              $scope.isAllowCheckout = isAllowCheckout;
              $scope.confirm = () ->
                $uibModalInstance.close()
              $scope.cancel = () ->
                $uibModalInstance.dismiss('cancel')
          ]
        });
    }
])
