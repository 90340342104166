'use strict';
import { isEmpty } from 'lodash-es';

app.controller('productVariationPickerController', [
  '$scope',
  '$uibModalInstance',
  'productService',
  'cartService',
  'mainConfig',
  '$rootScope',
  'imageService',
  'productSetService',
  'productStockService',
  'slFeatureService',
  function (
    $scope,
    $uibModalInstance,
    productService,
    cartService,
    mainConfig,
    $rootScope,
    imageService,
    productSetService,
    productStockService,
    slFeatureService,
  ) {
    $scope.variationItems = [];
    $scope.addItemQuantity = 1;
    $scope.variationSelected = {};
    $scope.slFeatureService = slFeatureService;

    $scope.state = {
      loading: false,
      checkoutReady: true,
      isRedeemGift: $scope.isRedeemGift || false,
    };

    const getCurrentGiftCartItems = (cart) => {
      return (
        cart?.items.filter(
          (item) =>
            $scope.promotionId === item.applied_promotion?.id &&
            item.product_id === $scope.product._id,
        ) || []
      );
    };

    const updateSelectedVariationInCart = () => {
      const productVariations = $scope.cart?.items.reduce((products, item) => {
        if (
          item.product_id === $scope.product._id &&
          item.variation_id &&
          $scope.promotionId === item?.applied_promotion?.id
        ) {
          products.push({
            variation: $scope.product.variations.find(
              (variation) => variation.key === item.variation_id,
            ),
            quantity: item.quantity,
            id: item.id,
          });
        }
        return products;
      }, []);

      $scope.variationItems = productVariations;
      return productVariations;
    };

    const afterProductLoaded = () => {
      $scope.product.digest_variations = [];
      $scope.hasStock = productStockService.checkHasStock(
        $scope.product,
        $scope.variationSelected,
      );

      const media = $scope.product.media[0];
      if (media) {
        $scope.productImageUrl =
          media.default_image_url || imageService.getMediaImageUrl(media);
        $scope.productImageAlt = media.alt_translations;
      }

      updateSelectedVariationInCart();
    };

    const getVariationId = () => {
      if ($scope.product.variations.length === 0) {
        return '';
      }
      return $scope.variationSelected
        ? $scope.variationSelected.key
        : $scope.product.variations[0].key;
    };
    $scope.showOutOfStockHint = function () {
      const productStockInfo = productStockService.getProductStockInfo(
        $scope.product,
        $scope.variationSelected,
      );
      return !productStockService.checkHasStock(
        productStockInfo,
        $scope.variationSelected,
      );
    };

    const updateHasStock = () => {
      $scope.hasStock = productStockService.checkHasStock(
        $scope.product,
        $scope.variationSelected,
      );
    };

    $scope.incQuantity = () => {
      if (parseInt($scope.addItemQuantity, 10) < 99999) {
        $scope.addItemQuantity = parseInt($scope.addItemQuantity, 10) + 1;
      }
    };

    $scope.decQuantity = () => {
      if (parseInt($scope.addItemQuantity, 10) > 1) {
        $scope.addItemQuantity = parseInt($scope.addItemQuantity, 10) - 1;
      }
    };

    $scope.addItemToCart = () => {
      const regex = /^[1-9][0-9]{0,4}$/;
      $scope.invalidQuantity = !regex.test($scope.addItemQuantity);

      if ($scope.invalidQuantity) {
        $rootScope.$broadcast('cartItemUpdateFailed');
        return;
      }

      const addItemQuantity = parseInt($scope.addItemQuantity, 10);
      const variationKey = $scope.variationSelected?.key;

      // redeem gift
      if ($scope.state.isRedeemGift) {
        $uibModalInstance.close({
          quantity: addItemQuantity,
          variation: $scope.variationSelected,
        });
        return;
      }

      if ($scope.getMinQuantityDiff() < addItemQuantity) {
        $scope.exceedRequiredQuantity = true;
        return;
      }

      $scope.state.loading = true;

      // build cart item
      cartService.prepareCart().then(() => {
        // check promotion is changed
        cartService.getCartItemsByFetch().then((cart) => {
          $scope.cart = cart.data.data;
          const currentItem = getCurrentGiftCartItems(cart.data.data);
          let targetCartItem = currentItem.find(
            (item) => item.variation_id === variationKey,
          );
          if (!targetCartItem) {
            targetCartItem = currentItem.find((item) =>
              isEmpty(item.variation_id),
            );
          }

          if (!targetCartItem) {
            $uibModalInstance.close();
            return;
          }

          const targetChannelId = $scope.cart.delivery_data?.target_channel_id;
          productService
            .checkStocks([
              {
                product_id: $scope.product._id,
                variation_id: variationKey,
                ...(targetChannelId
                  ? { target_channel_id: targetChannelId }
                  : {}),
              },
            ])
            .then(({ data }) => {
              const stock = data[0];
              $scope.product.max_order_quantity = stock.max_order_quantity;
              const result = cartService.checkStockResult(
                addItemQuantity,
                stock,
              );
              const isOutOfStockOrderable = productService.isOutOfStockOrderable(
                $scope.product,
              );

              $scope.quantityOfStock = result.quantityOfStock;
              $scope.orderQuantityStatus = result.orderQuantityStatus;
              $scope.variationSelected.cart_quantity = stock.cart_quantity;

              // should not add item to cart
              if (result.notEnoughStockQty >= 0 && !isOutOfStockOrderable) {
                $scope.state.loading = false;
                scrollToError();
                return;
              }

              const quantity = targetCartItem.variation_id
                ? targetCartItem.quantity + addItemQuantity
                : addItemQuantity;

              cartService
                .updateCartItem({
                  cartItemId: targetCartItem._id,
                  item: {
                    quantity,
                    variation_id: variationKey,
                  },
                })
                .then((cart) => {
                  $scope.cart = cart.data.data;
                  if ($scope.getMinQuantityDiff() === 0) {
                    $uibModalInstance.close();
                  }
                  updateSelectedVariationInCart();
                })
                .finally(() => {
                  $scope.state.loading = false;
                });
            });
        });
      });
    };

    const scrollToError = () => {
      const errorElement = $(
        '.product-variation-picker .variation-picker-error:visible',
      );
      if (errorElement.length > 0) {
        $('.product-variation-picker .modal-content').animate(
          {
            scrollTop: errorElement.offset().top,
          },
          300,
        );
      }
    };

    $scope.removeItem = (targetItem) => {
      if ($scope.state.loading) {
        return;
      }
      $scope.state.loading = true;

      cartService
        .deleteItems({
          id: targetItem.id,
          options: {
            is_cart_page: true,
            cache_key: mainConfig.currentPath,
          },
        })
        .then(() => {
          cartService.prepareCart().then(() => {
            cartService.getCartItemsByFetch().then((cart) => {
              $scope.cart = cart.data.data;
              if ($scope.getMinQuantityDiff() === 0) {
                $uibModalInstance.close();
              }
              updateSelectedVariationInCart();
            });
          });
        })
        .finally(() => {
          $scope.state.loading = false;
        });
    };

    $scope.getMinQuantityDiff = () => {
      return (
        getCurrentGiftCartItems($scope.cart).find((item) => !item.variation_id)
          ?.quantity || 0
      );
    };

    $scope.showStockQuantityHint = () => {
      return productStockService.showStockQuantityHint({
        product: $scope.product,
        variationSelected: $scope.variationSelected,
        checkoutReady: $scope.state.checkoutReady,
        quantityOfStock: $scope.quantityOfStock,
      });
    };

    const init = () => {
      $scope.getVariationName = productSetService.getChildVariationName;

      // watch after product-variation-dropdown loaded
      $scope.$watch('variationSelected', function checkStockQuantity() {
        updateHasStock();
        if (!$scope.product.unlimited_quantity) {
          const variation_id = getVariationId();
          if (isEmpty(variation_id)) {
            return;
          }
          $scope.orderQuantityStatus =
            cartService.QUANTITY_VERIFY_STATUS.IS_AVAILABLE;

          const targetChannelId = $scope.cart?.delivery_data?.target_channel_id;
          productService
            .checkStocks([
              {
                product_id: $scope.product._id,
                variation_id,
                ...(targetChannelId
                  ? { target_channel_id: targetChannelId }
                  : {}),
              },
            ])
            .then(({ data }) => {
              // check if variation id changed after request
              if (!variation_id || variation_id !== getVariationId()) {
                return;
              }

              const stock = data[0];
              const result = cartService.checkStockResult(0, stock);
              $scope.quantityOfStock = result.quantityOfStock;
              $scope.orderQuantityStatus = result.orderQuantityStatus;
              $scope.variationSelected.cart_quantity = stock.cart_quantity;
              $scope.variationSelected.quantity = stock.quantity;
              $scope.variationSelected.preorder_limit = stock.preorder_limit;
              scrollToError();
            });
        }

        $scope.exceedRequiredQuantity = false;
      });

      $scope.$on('product.gallery.selectMedia', (_, selectedMedia) => {
        $scope.productImageUrl =
          selectedMedia.default_image_url ||
          imageService.getMediaImageUrl(selectedMedia);
        $scope.productImageAlt = selectedMedia.alt_translations;
      });

      if (!_.isEmpty($scope.product)) {
        afterProductLoaded();
      }
    };

    init();
  },
]);
